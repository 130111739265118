import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";

const NewsletterForm = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleSuccess = e => {
    setFormDisabled(true);
    setShowToast(true);
    if (e) {
      console.log(e);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    fetch(`/.netlify/functions/mailchimp?name=${firstName}&email=${email}`)
      .then(handleSuccess)
      .catch(handleSuccess);
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h3 className="font-cb text-white">LAAS NEWS DELIVERY</h3>
        <p className="text-white">
          We've got our ears to the ground. Sign up and be the first in line to
          get LAAS news, updates, and insights, delivered straight to your inbox
        </p>
        <Form.Row>
          <Col>
            <Form.Control
              className="rounded-0 border-0"
              placeholder="First name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              disabled={formDisabled}
              required
            />
          </Col>
          <Col>
            <Form.Control
              className="rounded-0 border-0"
              placeholder="Email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled={formDisabled}
              required
            />
          </Col>
          <Col className="col-12 col-sm-auto pt-1 pt-sm-0">
            <button
              type="submit"
              disabled={formDisabled}
              className="rounded-0 border-0 btn btn-block laas-dark-grey laas-text-dr font-cb"
            >
              <span className="align-middle pr-1">
                <i className="fas fa-caret-right"></i>
              </span>
              <span className="align-middle">SEND ME LAAS NEWS</span>
            </button>
          </Col>
        </Form.Row>
      </Form>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong>Thanks for signing up!</strong>
        </Toast.Header>
      </Toast>
    </>
  );
};

export default NewsletterForm;
