import React from "react";
import "twin.macro";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "LAASlogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Navbar
        style={{ opacity: 0.9, boxShadow: "0.2px 0.2px 50px 0.2px #aba9a9" }}
        fixed="top"
        className="top-nav d-flex laas-basic-grey justify-content-between"
      >
        <Navbar.Brand className="font-cb">
          <Link className="navbar-item text-dark" to="/">
            Labour Against Antisemitism
          </Link>
        </Navbar.Brand>
        <Nav className="d-none d-md-flex font-grey">
          <Nav.Link as="div" className="parallelogram mx-2 px-3">
            <Link
              to="/donate"
              className="button navbar-item font-cb laas-text-dr"
            >
              DONATE NOW
            </Link>
          </Nav.Link>
          <Nav.Link as="div" className="parallelogram mx-2 px-3">
            <Link
              to="/report-antisemitism"
              className="button is-light navbar-item font-cb laas-text-dr"
            >
              REPORT IT
            </Link>
          </Nav.Link>
          <Nav.Link as="div" className="font-grey mx-2">
            <span className="icon mr-1">
              <i className="fab fa-twitter"></i>
            </span>
            <a
              href="https://twitter.com/labouragainstas"
              target="_blank"
              className="footer-social-link font-grey"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </Nav.Link>
          <Nav.Link as="div" className="font-grey mx-2">
            <span className="icon mr-1">
              <i className="fab fa-facebook-f"></i>
            </span>
            <a
              href="https://www.facebook.com/labouragainstAS/"
              target="_blank"
              className="footer-social-link font-grey"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </Nav.Link>
        </Nav>
        <Nav className="d-none d-sm-flex d-md-none font-grey">
          <Nav.Link as="div" className="font-grey mx-2">
            <a
              href="https://twitter.com/labouragainstas"
              target="_blank"
              className="footer-social-link font-grey"
              rel="noopener noreferrer"
            >
              <span className="icon mr-1">
                <i className="fab fa-twitter"></i>
              </span>
            </a>
          </Nav.Link>
          <Nav.Link as="div" className="font-grey mx-2">
            <a
              href="https://www.facebook.com/labouragainstAS/"
              target="_blank"
              className="footer-social-link font-grey"
              rel="noopener noreferrer"
            >
              <span className="icon mr-1">
                <i className="fab fa-facebook-f"></i>
              </span>
            </a>
          </Nav.Link>
        </Nav>
      </Navbar>
      <div className="mid-nav mt-1 mt-sm-5 mb-2">
        <div
          className="d-none d-sm-block m-auto"
          style={{ maxWidth: "300px", minWidth: "200px" }}
        >
          <Link to="/">
            <Img fluid={data.file.childImageSharp.fluid} />
          </Link>
        </div>
        <Navbar
          style={{ opacity: 0.9 }}
          expand="sm"
          className="lowest-nav d-flex justify-content-around navbar sticky-top font-cb laas-light-grey pb-md-0"
        >
          <Navbar.Brand
            className="font-cb d-sm-none"
            style={{ minWidth: "150px" }}
          >
            <Link to="/">
              <Img fluid={data.file.childImageSharp.fluid} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="font-grey justify-content-center"
          >
            <Nav>
              <Nav.Link as="div" tw="hidden! md:block!">
                <Link className="navbar-item font-grey" to="/about">
                  About
                </Link>
              </Nav.Link>
              <div className="nav-separator" tw="hidden! md:block!"></div>
              <Nav.Link as="div">
                <Link className="navbar-item font-grey" to="/reports">
                  Reports
                </Link>
              </Nav.Link>
              <div className="nav-separator"></div>
              <Nav.Link as="div">
                <Link className="navbar-item font-grey" to="/news">
                  News & Comment
                </Link>
              </Nav.Link>
              <div className="nav-separator"></div>
              <Nav.Link as="div">
                <Link className="navbar-item font-grey" to="/testimony">
                  Testimony
                </Link>
              </Nav.Link>
              <div className="nav-separator"></div>
              <Nav.Link as="div">
                <Link className="navbar-item font-grey" to="/pete-newbon">
                  Pete Newbon
                </Link>
              </Nav.Link>
              <div className="nav-separator" tw="hidden! md:block!"></div>
              <Nav.Link as="div" tw="hidden! md:block!">
                <a
                  href="http://stores.clothes2order.com/laaswear/"
                  target="_blank"
                  className="navbar-item font-grey"
                  rel="noopener noreferrer"
                >
                  Shop
                </a>
              </Nav.Link>
              <div className="nav-separator" tw="hidden! md:block!"></div>
              <Nav.Link as="div" tw="hidden! md:block!">
                <Link className="navbar-item font-grey" to="/contact">
                  Contact
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};
