import React from "react";
import "twin.macro";
import { Link, graphql, useStaticQuery } from "gatsby";

import moment from "moment";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

import NewsletterForm from "./NewsletterForm";

const Footer = () => {
  const {
    allTwitterStatusesUserTimelineLaasTimeline: { edges },
  } = useStaticQuery(graphql`
    query {
      allTwitterStatusesUserTimelineLaasTimeline {
        edges {
          node {
            created_at
            full_text
            id_str
          }
        }
      }
    }
  `);

  const formatDateFromNow = (dateString) => {
    const format = "ddd MMM DD HH:mm:ss ZZ";

    return moment(dateString, format)
      .local()
      .fromNow();
  };

  const getColClassName = (index) => {
    const base = "col-12 col-sm-5 col-md-4";
    return index === 0 ? `${base} offset-sm-1 offset-md-2 pb-5` : base;
  };

  const scrollToTop = () => {
    if (typeof window !== undefined) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

  return (
    <footer>
      <div className="laas-logo-bg">
        <Row className="pt-5 px-3" tw="mx-0!">
          <div className="col-12 col-sm-11 col-md-10 offset-sm-1 offset-md-2 pb-3 text-white">
            <div>
              <i className="fas fa-caret-down"></i>
            </div>
            <h4 className="font-cb text-white">LATEST TWEETS</h4>
          </div>
          {edges.slice(0, 2).map((edge, index) => (
            <Col className={getColClassName(index)} key={edge.node.id_str}>
              <Card className="bg-transparent border-0">
                <Card.Subtitle className="text-white font-weight-bold pb-2">
                  <span>
                    <a
                      href="https://twitter.com/labouragainstas"
                      target="_blank"
                      className="text-white"
                      rel="noopener noreferrer"
                    >
                      @LabourAgainstAS
                    </a>
                  </span>
                  <span> &bull; </span>
                  <span>{formatDateFromNow(edge.node.created_at)}</span>
                </Card.Subtitle>
                <Card.Text className="text-white">
                  {edge.node.full_text}
                </Card.Text>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="mx-3 py-5">
          <Col className="py-md-5 col-12 col-md-8 offset-md-2">
            <NewsletterForm />
          </Col>
        </Row>
      </div>
      <Row className="py-3 laas-dark-grey py-5" tw="mx-0!">
        <Col className="col-sm-3 offset-sm-2 col-md-2 offset-md-3 col-6 d-flex justify-content-center">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/reports">
                Reports
              </Link>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/news">
                News & Comment
              </Link>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/testimony">
                Testimony
              </Link>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/pete-newbon">
                Pete Newbon
              </Link>
            </li>
          </ul>
        </Col>
        <Col className="col-sm-3 col-md-2 col-6 d-flex justify-content-left">
          <ul className="nav flex-column">
            <li className="nav-item">
              <a
                href="http://stores.clothes2order.com/laaswear/"
                target="_blank"
                className="footer-social-link laas-text-main-grey"
                rel="noopener noreferrer"
              >
                Shop
              </a>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/contact">
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/donate">
                Donate now
              </Link>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/report-antisemitism">
                Report it
              </Link>
            </li>
            <li className="nav-item">
              <Link className="laas-text-main-grey" to="/press">
                Press enquiries
              </Link>
            </li>
          </ul>
        </Col>
        <Col className="nav flex-sm-column justify-content-around justify-content-sm-start pt-2 pt-sm-0">
          <ul className="nav flex-sm-column justify-content-around pt-2 pt-sm-0 w-100">
            <li className="nav-item">
              <a
                href="https://twitter.com/labouragainstas"
                target="_blank"
                className="footer-social-link laas-text-main-grey"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.facebook.com/labouragainstAS/"
                target="_blank"
                className="footer-social-link laas-text-main-grey"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.youtube.com"
                target="_blank"
                className="footer-social-link laas-text-main-grey"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
            </li>
          </ul>
        </Col>
        <Col className="col-md-2 col-12 align-items-middle text-center pt-3">
          <div className="d-inline-block text-center" onClick={scrollToTop}>
            <div className="fa-stack">
              <i className="fas fa-square fa-stack-2x laas-text-dr"></i>
              <i className="fas fa-caret-up fa-stack-1x fa-inverse"></i>
            </div>
            <p className="font-cb text-dark">Back to top</p>
          </div>
        </Col>
      </Row>
      <Row className="laas-lighter-grey justify-content-center" tw="mx-0!">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link laas-text-main-grey" to="/terms">
              Terms & Conditions
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link laas-text-main-grey" to="/privacy">
              Privacy Policy
            </Link>
          </li>
          <li className="nav-item">
            <div className="nav-link laas-text-main-grey">
              Copyright LAAS &copy;
            </div>
          </li>
        </ul>
      </Row>
    </footer>
  );
};

export default Footer;
